// @flow
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import type { Node as ReactNode } from 'react';
import styles from './Layout.module.scss';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  pathname: string,
  author: string
};

const Layout = ({ children, title, description, pathname, author }: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            url
          }
        }
      }
    `}
    render={data => (
      <div className={styles.layout}>
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`${data.site.siteMetadata.url}${pathname}twitter-card.jpg`}
          />
          <meta name="description" content={description} />
          <meta property="og:site_name" content={title} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:creator" content={`@${author}`} />
        </Helmet>
        {children}
      </div>
    )}
  />
);

export default Layout;
