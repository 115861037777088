import React, { useState } from 'react';
import * as styles from './EmailListForm.module.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailListForm = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === '') {
      alert("Email can't be empty");
      return;
    }

    addToMailchimp(email)
      .then((data) => {
        alert('Successfully subscribed');
      })
      .catch((error) => {
        // Errors in here are client side
        // Mailchimp always returns a 200

        // redirect to subscribe url if there is an unsupported browser (e.g. Brave)
        const subscribLink = `https://ronatory.us20.list-manage.com/subscribe/post?u=c886f0c591f7599726fe5c8d4&amp;id=01390dc5ee&MERGE0=${email}`;
        window.open(subscribLink, '_blank');
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.EmailListForm}>
      <i>
        <small className={styles.Header}>
          Subscribe for new posts. Currently launching{' '}
          <a href="https://www.psychofit.eu" rel="noopener noreferrer">
            <b>Psychofit</b>
          </a>{' '}
          and{' '}
          <a href="https://artifairy.ai" rel="noopener noreferrer">
            <b>ArtifAIry.ai</b>
          </a>
        </small>
      </i>
      <div className={styles.Wrapper}>
        <input
          placeholder="you@awesome.com"
          name="email"
          type="email"
          onChange={handleEmailChange}
        />
        <button type="submit">Subscribe</button>
      </div>
    </form>
  );
};

export default EmailListForm;
