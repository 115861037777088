// @flow
import { ICONS } from '../constants';

const getIcon = (name: string) => {
  let icon;

  switch (name) {
    case 'twitter':
      icon = ICONS.TWITTER;
      break;
    case 'github':
      icon = ICONS.GITHUB;
      break;
    case 'email':
      icon = ICONS.EMAIL;
      break;
    case 'facebook':
      icon = ICONS.FACEBOOK;
      break;
    case 'instagram':
      icon = ICONS.INSTAGRAM;
      break;
    case 'unsplash':
      icon = ICONS.UNSPLASH;
      break;
    case 'stackoverflow':
      icon = ICONS.STACKOVERFLOW;
      break;
    case 'spotify':
      icon = ICONS.SPOTIFY;
      break;
    default:
      icon = {};
      break;
  }

  return icon;
};

export default getIcon;
